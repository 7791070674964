.action-buttons {
  padding: 10px 0;
  display: flex;
}

@media (max-width: 768px) {
  .action-buttons {
    padding: 0;
    .level-left {
      margin-top: 1.5rem;
      display: flex;

      .level-item {
        margin: 0 1rem 0 0;
      }
    }
  }
}
