@import "./fonts";

.container {
  padding-right: 1.25rem;
  padding-left: 1.25rem;
}

.App-header__logo .logo img {
  height: 2rem;
}

.__social-share-container {
  margin-bottom: 1.6rem;
}

.incubated-by-wrapper {
  margin-top: 2rem;
}

@media (max-width: 768px) {
  .banner-wrapper {
    text-align: center;
  }
}
.incubated-by-wrapper {
  margin-top: 2rem;
}
